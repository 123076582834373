<template>
  <b-modal
    v-model="noti.enable"
    size="sm"
    centered
    scrollable
    :title="noti.title"
    :header-bg-variant="noti.variant"
    header-text-variant="light"
    ref="modal"
    header-close-variant="close"
    :hide-footer="isResend"
  >
    {{ noti.message }}
    <div v-if="isResend">
      <span
        >The email address associated with this account has not yet been
        verified. Click the link in the Notisphere email to verify your account.
        If you can’t find the email, check your spam folder or click the link
        below to resend it.</span
      >
    </div>
    <div v-if="isResend" style="padding: 20px 0px 5px 45px">
      <b-button
        variant="btn btn-primary"
        @click="confirm"
        size="sm"
        class="m-0"
        v-if="isResend"
      >
        {{ noti.buttonTitle }}
      </b-button>
    </div>
    <template v-slot:modal-footer="{}">
      <b-button
        variant="outline-secondary"
        outlined
        @click="cancel"
        size="sm"
        class="m-0 mr-2"
        hidden
      >
        Cancel
      </b-button>
      <!-- <b-button variant="outline-secondary" outlined @click="cancel" size="sm" class="m-0 mr-2" v-if="isResend">
                Cancel
            </b-button> -->
      <b-button
        variant="btn btn-primary"
        @click="confirm"
        size="sm"
        class="m-0"
        v-if="isResend"
      >
        {{ noti.buttonTitle }}
      </b-button>
      <b-button
        variant="secondary"
        @click="confirm"
        size="sm"
        class="m-0"
        v-if="!isResend"
      >
        {{ noti.buttonTitle }}
      </b-button>
    </template>
  </b-modal>
</template>

<style lang="scss" scoped>
::v-deep .modal-content {
  border: 0px;
}
</style>

<script>
export default {
  data() {
    return {
      noti: {
        enable: false,
        id: null,
        title: null,
        message: null,
        buttonTitle: "Confirm",
        variant: null,
      },
      isResend: false,
      resolve: null,
    };
  },
  methods: {
    showConfirm(message, options = {}) {
      this.noti = {
        enable: false,
        id: this.id,
        title: "Are you sure?",
        message: message,
        variant: "danger",
        buttonTitle: "Confirm",
        //variant: 'success',
        ...options,
      };
      this.isResend = false;
      //debugger
      this.$nextTick(() => {
        this.noti.enable = true;
      });
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    showResend(message, options = {}) {
      this.noti = {
        enable: false,
        id: this.id,
        title: "Are you sure?",
        message: message,
        variant: "danger",
        buttonTitle: "Resend Verification Email",
        //variant: 'success',
        ...options,
      };
      this.isResend = true;
      //debugger
      this.$nextTick(() => {
        this.noti.enable = true;
      });
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    confirm() {
      this.resolve(true);
      this.noti.enable = false;
    },
    cancel() {
      this.resolve(false);
      this.noti.enable = false;
    },
  },
};
</script>
