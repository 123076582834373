export default {
    props: {
        value: {},
        type: { type: String, default: 'text'},
        id: { type: String, default: 'text'},
        label: { type: String, default: ''},
        placeholder: { type: String, default: ''},
        rules: { type: String, default: ''},
        validatorName: { type: String, default: ''},
        disabled: { type: Boolean, default: false},
        hideDetails: { type: Boolean, default: false}
    },
    data(){
        return {
            fsWidth: 0,
            floatLabel: false,
            isFocus: false,
            isError: ''
        }
    },
    mounted(){
        this.checkFloatLabel(this._value)
    },
    computed: {
        disabledValidate() {
            return !this.rules || this.rules == '' ? true : false
        },
        _value: {
            get(){
                return this.value
            },
            set(val){
                this.$emit('input', val)
            }
        },
        lblWidth: {
            get() {
                if (this.floatLabel)
                    return this.fsWidth + 'px'
                return 0
            }
        }
    },
    watch: {
        _value(val){
            //debugger
            this.checkFloatLabel(val)
        },
        'isError'(val){
            //debugger
        }
    },
    methods: {     
        onUpdate() {
            this.$emit('update')
        },

        clickLabel() {
            //
            this.$refs.theInput.focus()
        },

        fieldFocus() {
            this.addFloatLabel()
            this.addFocus()
        },
        fieldBlur() {
            
            if(!this._value){
                this.removeFloatLabel()
            }
            this.removeFocus()
            //debugger
            if(this.$refs.wrapper.$el.children[0].childNodes[1].children[1].id == "sup1")
            {
                var cnt = 0;
                //debugger
                var classList = this.$refs.wrapper.$el.parentElement.parentElement.parentElement.parentElement.children[2] == undefined ? this.$refs.wrapper.$el.parentElement.parentElement.parentElement.parentElement.children[1].classList : this.$refs.wrapper.$el.parentElement.parentElement.parentElement.parentElement.children[2].classList;
                var cntN = this.$refs.wrapper.$el.parentElement.parentElement.parentElement.parentElement.children[2] == undefined ? this.$refs.wrapper.$el.parentElement.parentElement.parentElement.parentElement.children[1].children[2].children[0].children[0].children[0].childElementCount : this.$refs.wrapper.$el.parentElement.parentElement.parentElement.parentElement.children[2].children[2].children[0].children[0].children[0].childElementCount;
                cnt = cntN;
                var IsError = this.$refs.wrapper.$el.parentElement.parentElement.parentElement.parentElement.children[2] == undefined;
                // if(classList == "row")
                // {
                //     cnt = this.$refs.wrapper.$el.parentElement.parentElement.parentElement.parentElement.children[2].children[2].children[0].children[0].children[0].childElementCount;
                // }
                // else {
                //     cnt = this.$refs.wrapper.$el.parentElement.parentElement.parentElement.parentElement.children[1].children[2].children[0].children[0].children[0].childElementCount;
                // }
                ////debugger
                if(this.$refs.wrapper.$el.children[0].children[2].textContent != "" || cnt == 0)
                {
                    //debugger
                    //disable
                    
                    this.$refs.wrapper.$el.parentElement.parentElement.parentElement.parentElement.parentElement.lastElementChild.children[1].hidden = false
                    //enable
                    this.$refs.wrapper.$el.parentElement.parentElement.parentElement.parentElement.parentElement.lastElementChild.children[2].hidden = true 
                    this.$refs.wrapper.$el.parentElement.parentElement.parentElement.parentElement.parentElement.children[1].children[1].hidden = false
                }
                else
                {
                    // debugger
                    // if(this.$refs.wrapper.$el.children[0].childNodes[1].children[1].textContent != "" && this.$refs.wrapper.$el.children[0].children[2].textContent != "")
                    // {
                    //disable
                    //debugger
                    if(!IsError)
                    this.$refs.wrapper.$el.parentElement.parentElement.parentElement.parentElement.parentElement.children[1].children[1].hidden = true
                    this.$refs.wrapper.$el.parentElement.parentElement.parentElement.parentElement.parentElement.lastElementChild.children[1].hidden = true
                    //enable
                    this.$refs.wrapper.$el.parentElement.parentElement.parentElement.parentElement.parentElement.lastElementChild.children[2].hidden = false 
                    //}
                    
                }   
            }
        },
        checkFloatLabel(val) {
            if(!val && !this.isFocus){
                this.removeFloatLabel()
            } else {
                this.addFloatLabel()
            }
        },
        addFloatLabel() {
            
            if(this.$refs.wrapper.$el.textContent != "" && this.$refs.wrapper.$el.textContent != "Field required" && this.$refs.wrapper.$el.children[0].childNodes[1].children[1].id != 'sup' && this.$refs.wrapper.$el.children[0].childNodes[1].children[1].id != 'sup1')
            {
                ////debugger
                this.$refs.wrapper.$el.classList.add('float-label')
                setTimeout(()=> {
                    this.fsWidth = this.$refs.lbl.clientWidth
                }, 250)
                this.floatLabel = true
            }
        },
        removeFloatLabel() {
            if(this.$refs.wrapper.$el.textContent != ""  && this.$refs.wrapper.$el.textContent != "Field required")
            {
                this.$refs.wrapper.$el.classList.remove('float-label')
                this.floatLabel = false
            }
        },
        addFocus() {
            this.$refs.wrapper.$el.classList.add('focus')
            this.isFocus = true
        },
        removeFocus() {
            this.$refs.wrapper.$el.classList.remove('focus')
            this.isFocus = false
        },
        getClassIndicate(validationContext){
            ////debugger
            if(!validationContext.validated)
            {
                //debugger
            
            }
            if(validationContext.validated || validationContext.dirty){
                var IsVal = this._getValidationState(validationContext) ? 'valid' : 'fail'
                if(IsVal == 'fail')
                {
                    if(this.$refs.wrapper != undefined)
                    {
                        if(this.$refs.wrapper.$el.children[0].childNodes[1].children[1].id == "sup1")
                        {
                            //debugger
                            if(this.$refs.wrapper.$el.children[0].childNodes[1].children[1].textContent == "" || this.$refs.wrapper.$el.children[0].children[2].textContent != "" || this.$refs.wrapper.$el.parentElement.parentElement.parentElement.parentElement.children[1].children[2].children[0].children[0].children[0].childElementCount == 0)
                            {
                                //debugger
                                this.$refs.wrapper.$el.parentElement.parentElement.parentElement.parentElement.parentElement.lastElementChild.children[1].hidden = false
                                this.$refs.wrapper.$el.parentElement.parentElement.parentElement.parentElement.parentElement.lastElementChild.children[2].hidden = true 
                            }
                            else
                            {
                                //debugger
                                // if(this.$refs.wrapper.$el.children[0].childNodes[1].children[1].textContent != "")
                                // {
                                    this.$refs.wrapper.$el.parentElement.parentElement.parentElement.parentElement.parentElement.lastElementChild.children[1].hidden = true
                                    this.$refs.wrapper.$el.parentElement.parentElement.parentElement.parentElement.parentElement.lastElementChild.children[2].hidden = false 
                                //}
                            }   
                        }
                    }
                }
                return IsVal
            } return ''
        },
    }
}