import route from "../misc/route";
import { USER_TYPE } from "../misc/commons";

const META_AUTH_REQUIRED = {
  auth: true,
};

// for lazy loading
const Login = () => import("../views/share/login");
const Signup = () => import("../views/share/signup");
const SuccessPage = () => import("../views/share/success-page");
const ForgotPassword = () => import("../views/share/forgot-password");
const NotFound = () => import("../views/share/404");
const Forbidden = () => import("../views/share/404");
const Account = () => import("../views/share/account");
const EditAccount = () => import("../views/share/account/edit-account");
const User = () => import("../views/share/user");
const Role = () => import("../views/share/role");

const UserGuide = () => import("../views/share/user-guide");

//callback-url-handler
const AccountVerification = () =>
  import("../views/callback-url-handler/AccountVerification");

const ResetPassword = () =>
  import("../views/callback-url-handler/ResetPassword");
const recalls = () => import("../views/recalls/list/tableView");
export const paths = {
  //callback-url-handler
  accountVerification: route.register(
    "/verify-email",
    "AccountVerification",
    AccountVerification,
    { auth: false, layout: "layout-simple" }
  ),
  resetPassword: route.register(
    "/reset-password",
    "ResetPassword",
    ResetPassword,
    { auth: false, layout: "layout-simple" }
  ),

  //shared
  login: route.register("/login", "Login", Login, {
    auth: false,
    layout: "layout-simple",
  }),
  recalls: route.register("/recalls", "Recalls", recalls, {
    auth: true,
    layout: "layout-default",
    breadCrumb: "Home",
  }),
  //recalls: route.register( '/recalls', 'Recalls', Recalls, { ...META_AUTH_REQUIRED, breadCrumb: 'Recall List' } ),
  signup: route.register("/signup", "Signup", Signup, {
    auth: false,
    layout: "layout-simple",
  }),
  forgotPassword: route.register(
    "/forgot-password",
    "ForgotPassword",
    ForgotPassword,
    { auth: false, layout: "layout-simple" }
  ),
  successPage: route.register("/success", "SuccessPage", SuccessPage, {
    auth: false,
    layout: "layout-simple",
  }),
  notFound: route.register("/not-found", "NotFound", NotFound, {
    ...META_AUTH_REQUIRED,
    breadCrumb: "404",
  }),
  forbidden: route.register("/forbidden", "Forbidden", Forbidden, {
    ...META_AUTH_REQUIRED,
    breadCrumb: "404",
  }),
  account: route.register("/account", "Account", Account, {
    ...META_AUTH_REQUIRED,
    breadCrumb: "Profile",
  }),
  editAccount: route.register("/account/edit", "Edit Account", EditAccount, {
    ...META_AUTH_REQUIRED,
    breadCrumb: "Edit",
  }),
  userGuide: route.register("/user-guide", "UserGuide", UserGuide, {
    ...META_AUTH_REQUIRED,
    breadCrumb: "User Guide",
  }),
};

export default paths;
