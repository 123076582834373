const conf = {
  BASE_URL: process.env.VUE_APP_BASE_URL,
  API_URL: process.env.VUE_APP_BASE_API,
  CLIENT_ID: process.env.VUE_APP_CLIENT_ID,
  LITE_URL: process.env.VUE_APP_BASE_LITE,
  SITE_KEY: process.env.VUE_APP_SITE_KEY,
  SECRET_KEY: process.env.VUE_APP_SECRET_KEY,
}
export default conf

