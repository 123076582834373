import { getToken, getOrgId, getUserType } from '../misc/auth'

export default {
  status: '',
  token: getToken(),
  user: '',
  isAuth: false,
  inited: false,
  currentRoute: {},
  lastedRouteNotAuth: '/recalls',
  previousRoute: '/',
  authRoutes: [ '/login' ],
  meta: null,
  // userType: getUserType(),
  // userType: 'NOTISPHERE',
  //orgId: getOrgId(),
  //permissions: null,
  loading: false,
  selectUserTypes: null,
  organization: null
}
