import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import i18n from './i18n'
import './components'
import './plugins'
import './filters'
import './rules'
import './mixins'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import  { LicenseManager } from 'ag-grid-enterprise'
import  "ag-grid-community/styles/ag-grid.css";

import 'ag-grid-community/styles/ag-theme-alpine.css'
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import UUID from 'vue-uuid'
//import { VueReCaptcha } from 'vue-recaptcha-v3'
//import VueRecaptcha from 'vue-recaptcha';
// Vue.use(VueReCaptcha, { siteKey: '6LfAPM4aAAAAAOQcYLp6FVh3FFu0-NJgLekdRY1q',loaderOptions: {
//   useRecaptchaNet: false,
//   autoHideBadge: true
//   //explicitRenderParameters:{ badge:"inline" }
// } })
//Vue.component(VueReCaptcha)
//Vue.component(VueRecaptcha)
// import VueRecaptcha from 'vue-recaptcha';
// Vue.use(VueRecaptcha, { siteKey: '6LcYIc4aAAAAAK5SrqaHgOf6ZzAT_GK48Kppjem6',loaderOptions: {
//   useRecaptchaNet: true
// } })

LicenseManager.setLicenseKey("CompanyName=NotiSphere,LicensedGroup=Notisphere,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=4,LicensedProductionInstancesCount=1,AssetReference=AG-031863,SupportServicesEnd=25_August_2023_[v2]_MTY5MjkxODAwMDAwMA==c1c2fd62c68db8a0aad7c3dc4d8e5db4");


Vue.use(UUID); 
Amplify.configure(awsconfig);

// >>New - Configuring Auth Module
Auth.configure(awsconfig);

library.add(faUserSecret)
Vue.component('font-awesome-icon', FontAwesomeIcon)

window.moment = require('moment')
window._ = require('lodash')  

Vue.use(require('vue-moment'))

Vue.config.productionTip = false

import { ValidationObserver, ValidationProvider } from 'vee-validate'
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
// import VueAnalytics from 'vue-analytics'
// Vue.use(VueAnalytics, {
//   id: '256052858'
// })
import VueLazyLoad from 'vue-lazyload'
Vue.use(VueLazyLoad)

import LoadScript from 'vue-plugin-load-script'
Vue.use(LoadScript)

import vuescroll from 'vuescroll'
Vue.use(vuescroll, { 
  ops: {
    bar: {background: 'rgba(99, 99, 99, 0.2)'}
  } 
})
Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his childrens
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
