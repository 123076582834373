<template>
  <div id="app">
    <component :is="layout" />
    <core-loading />
    <core-notify ref="notify" />
    <core-toast ref="toast" />
    <core-confirm ref="confirm" />
  </div>
</template>
<script src="https://www.google.com/recaptcha/api.js" async defer></script>
<script
  src="https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
  async
  defer
></script>
<script
  src="https://www.google.com/recaptcha/api.js?onload=vueRecaptchaInit&render=explicit"
  async
  defer
></script>

<style lang="scss">
.ag-theme-alpine {
  font-family: Rubik, sans-serif !important;
}

.ag-theme-alpine .ag-row-loading {
  visibility: hidden;
}

.ag-root-wrapper {
  border: none !important;
}

.ag-theme-alpine .ag-header-viewport {
  background-color: #fff;
}

.ag-theme-alpine .ag-column-select-header {
  display: none;
}

.ag-theme-alpine .ag-row {
  background-color: #fff;
  text-align: left;
  line-height: 1.5rem !important;
}

.ag-theme-alpine .ag-header-cell-label {
  font-weight: bold;
  color: black;
}

.ag-theme-alpine .ag-header-cell {
  background-color: #fff;
  padding-left: 10px;
  padding-right: 10px;
}

.ag-header-cell:hover {
  background: rgb(239, 239, 239) !important;
}

div.ag-row-hover:not(.ag-row-group) div {
  background: rgb(239, 239, 239) !important;
  cursor: pointer;
}

.ag-theme-alpine .ag-cell {
  border-color: rgba(0, 0, 0, 0) !important;

  font-size: 0.875rem;

  display: flex;

  justify-content: space-between;

  align-items: center;

  color: black;

  line-height: 21px;
}

.ag-cell-wrapper {
  display: inline-block !important;
}

.ag-row {
  z-index: 0 !important;
}

.ag-row.ag-row-focus {
  z-index: 1 !important;
}
</style>

<script>
export default {
  computed: {
    layout() {
      return this.$route.meta.layout || "layout-default";
      //return 'layout-default'
    },
  },
  mounted() {
    window.vm = this.$refs.notify || null;
    window.vmToast = this.$refs.toast || null;
  },
  watch: {
    $route(to, from) {
      document.title = to.meta.title || "Notisphere";
    },
  },
};
</script>
